import React, { Component } from 'react';
import { Provider } from 'react-redux';

import store from './src/store';
import * as muiTheme from './src/muiTheme';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

type Props = {};
class Main extends Component<Props> {
  render() {
    return (
      <ThemeProvider theme={theme}>
        {this.props.children}
       </ThemeProvider>
    );
  }
}

// Create a theme instance.
const theme = createMuiTheme(muiTheme.theme);

export default ({ element }) => {
  return (
    <Provider store={store}>
      <Main>{element}</Main>
    </Provider>
  )
}